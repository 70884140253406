import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Button } from "antd";

import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {
  ThirdPartyDraggable,
} from "@fullcalendar/interaction";

import { IoCloseCircle } from "react-icons/io5";
import { toast } from "sonner";
import {
  setCardModalActive,
  setCreateTaskModalActive,
  setColumnSelected,
  setUpgradeVisible,
} from "../../redux/appSlice";

import {
  fetchBothCalendarEvents,
  fetchTaskEventsFromTasks,
  setContextMenuActiveForEvent,
  stageGoogleEvent,
  updateAppleCalendarEvent,
  updateGoogleCalendarEvent,
  updateOutlookCalendarEvent,
  updateTaskEvent,
  updateTaskFromEvent,
} from "../../redux/calendarSlice";

import TaskCalendarEvent from "./TaskCalendarEvent";

import AppleCalendarEvent from "./AppleCalendarEvent";

import GoogleCalendarEvent from "./GoogleCalendarEvent";
import {
  analytics,
  closestColorSet,
  colorSets,
  darkColorSets,
} from "../../utils";
import MoreLink from "./MonthView/MoreLink";
import { ca } from "date-fns/locale";
import OutlookCalendarEvent from "./OutlookCalendarEvent";
import { isEqual } from "lodash";

export default function CustomFullCalendar({
  calendar_view = "month",
  todayMode = false,
}) {
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const bulkSelectedTasks = useSelector(
    (state) => state.app.bulkSelectedTasks || []
  );

  const hide_calendar_sidebar = useSelector(
    (state) => state.app.currentUser.hide_calendar_sidebar
  );
  const hide_sidebar = useSelector(
    (state) => state.app.currentUser.hide_sidebar
  );
  const active_calendars = useSelector(
    (state) => state.app.currentUser.active_calendars ?? {},
    isEqual
  );
  const calendar_first_day_of_week = useSelector(
    (state) => state.app.currentUser.calendar_first_day_of_week ?? 0
  );
  const calendar_show_weekends = useSelector(
    (state) => state.app.currentUser.calendar_show_weekends ?? true
  );
  const calendar_snap_duration = useSelector(
    (state) => state.app.currentUser.calendar_snap_duration ?? "00:15:00"
  );
  const calendar_zoom_level = useSelector(
    (state) => state.app.currentUser.calendar_zoom_level ?? 100
  );
  const show_declined_events = useSelector(
    (state) => state.app.currentUser.show_declined_events ?? true
  );
  const apple_cal_color = useSelector(
    (state) => state.app.currentUser.apple_cal_color ?? {},
    isEqual
  );
  const time_format = useSelector(
    (state) => state.app.currentUser.time_format ?? "12_hour"
  );
  const slotMinTime = useSelector(
    (state) => state.app.currentUser.slotMinTime ?? "00:00"
  );

  const timeFormat = time_format === "12_hour";

  const appleEvents = useSelector((state) => state.calendar.appleEvents || {});

  const googleEvents = useSelector(
    (state) => state.calendar.googleEvents || {}
  );
  const googleEventData = useSelector(
    (state) => state.calendar.googleEventData
  );

  const outlookEvents = useSelector(
    (state) => state.calendar.outlookEvents || {}
  );

  const calendarRef = useRef(null);

  const { active } = useSelector(
    (state) => state.app.createTaskModalActive || {}
  );

  const date = useSelector((state) => state.tasks?.calendarDate);
  const userId = useSelector((state) => state.app.uid);
  const dispatch = useDispatch();

  const [eventChanges, setEventChanges] = useState({});
  // When we make a change, let's create a timer to save it. If we make another change before the timer is up, we'll cancel the timer and start a new one.
  const [eventChangeTimer, setEventChangeTimer] = useState(null);

  const active_calendars_ids_count = useMemo(() => {
    var ids = [];

    Object.keys(active_calendars || []).forEach((key) => {
      (active_calendars[key]?.calendars || []).forEach((key2) => {
        ids.push(key2);
      });
    });

    return ids.length;
  }, [active_calendars]);

  const taskEvents = useSelector((state) => state.calendar.taskEvents);

  const scrollTime = moment().subtract(1, "hour").format("HH:mm:ss");

  const tasks = useSelector((state) => state.tasks.data);

  const labels = useSelector((state) => state.labels.data);

  const [allTasksForDay, setAllTasksForDay] = useState([]);
  const [dateForCurrentDay, setAllDateForCurrentDay] = useState([]);
  const [moreLinkActive, setMoreLinkActive] = useState(false);

  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        calendarApi.gotoDate(date);
      }
    }
  }, [date]);

  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      if (calendarApi && !active) {
        calendarApi.unselect();
      }
    }
  }, [active]);

  useEffect(() => {
    if (subscriptionActive) {
      let syncInterval;

      // Then set up the interval to dispatch it every 30 minutes
      syncInterval = setInterval(() => {
        dispatch(fetchBothCalendarEvents());
      }, 30 * 60 * 1000); // 30 minutes in milliseconds

      // Clear the interval when the menu is disabled
      return () => {
        if (syncInterval) {
          clearInterval(syncInterval);
        }
      };
    }
  }, []);

  useEffect(() => {
    function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result;
    }
    var colourIsLight = function (hex) {
      // Convert hex to RGB first (removing #)
      var rgb = hexToRgb(hex);

      if (rgb) {
        var r = parseInt(rgb[1], 16);
        var g = parseInt(rgb[2], 16);
        var b = parseInt(rgb[3], 16);

        // Counting the perceptive luminance
        // human eye favors green color...
        var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return a < 0.5;
      }

      return true;
    };

    if (document && bulkSelectedTasks.length <= 1) {
      let draggableEl = document.getElementById("app-container");
      let draggable = new ThirdPartyDraggable(draggableEl, {
        itemSelector: ".card-draggable",
        // mirrorSelector: ".card-drag-preview",
        eventData: function (eventEl) {
          let id = eventEl.getAttribute("taskId");
          const task = tasks[id];
          const taskEvent = taskEvents[id];

          if (task) {
            // convert from seconds to 00:00 format
            const duration = moment.duration(
              task.estimated_time || 0,
              "seconds"
            );
            // Convert duration (in seconds) to 00:00:00 format
            // preserving the 00:00:00 format
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            var m = moment().utcOffset(0);
            m.set({
              hour: hours,
              minute: minutes,
              second: seconds,
            });

            var durationString = m.format("HH:mm:ss");

            if (durationString === "00:00:00") {
              // Set it to 30 mins
              durationString = "00:30:00";
            }

            var backgroundColor = labels?.[task.label]?.color || "#FFE3C9";
            var textColor = colourIsLight(
              labels?.[task.label]?.color || "#FFE3C9"
            )
              ? "black"
              : "white";

            return {
              id: id,
              title: `${task.description}`,
              duration: durationString,
              backgroundColor: backgroundColor,
              textColor: textColor,
              extendedProps: {
                task: task,
                type: "task",
                previewFromTimebox: true,
              },
            };
          }
        },
      });

      // a cleanup function
      return () => {
        if (draggable && draggable.destroy) {
          draggable.destroy();
        }
      };
    }
  }, [tasks, bulkSelectedTasks]);

  useEffect(() => {
    if (calendarRef.current) {
      delay(500).then(() => {
        let calendarApi = calendarRef.current.getApi();
        if (calendarApi) {
          calendarApi.updateSize();
        }
      });
    }
  }, [hide_calendar_sidebar, hide_sidebar, calendar_zoom_level]);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const user_theme = useSelector(
    (state) => state.app.currentUser.user_theme ?? "system"
  );

  function getThemeFromUser(user_theme) {
    if (user_theme == "system") {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    } else {
      return user_theme;
    }
  }

  useEffect(() => {
    if (subscriptionActive) {
      if (userId) {
        dispatch(fetchBothCalendarEvents());
      }
    }
  }, [userId, date, active_calendars_ids_count]);

  useEffect(() => {
    if (subscriptionActive) {
      // Let's fetch events and process
      dispatch(fetchTaskEventsFromTasks());
    }
  }, [tasks, labels, date]);

  // useEffect to go through each eventChange when the timer is done
  useEffect(() => {
    if (eventChangeTimer) {
      clearTimeout(eventChangeTimer);
    }

    if (Object.values(eventChanges).length > 0) {
      const timer = setTimeout(() => {
        // Go through each eventChange

        Object.values(eventChanges).forEach((eventChange) => {
          dispatch(updateTaskFromEvent(eventChange));
        });

        // Clear the eventChanges
        setEventChanges([]);
      }, 2000);
      setEventChangeTimer(timer);
    }
  }, [eventChanges]);

  function calculateSlotDurationForZoomLevel(zoomLevel) {
    if (zoomLevel === 70) {
      return {
        minutes: 60,
      };
    }

    if (zoomLevel === 80) {
      return {
        minutes: 30,
      };
    }

    if (zoomLevel === 90) {
      return {
        minutes: 20,
      };
    }

    if (zoomLevel === 100) {
      return {
        minutes: 15,
      };
    }

    if (zoomLevel === 110) {
      return {
        minutes: 12,
      };
    }

    if (zoomLevel === 120) {
      return {
        minutes: 10,
      };
    }

    if (zoomLevel === 130) {
      return {
        minutes: 6,
      };
    }

    if (zoomLevel === 140) {
      return {
        minutes: 5,
      };
    }

    return "00:15:00";
  }

  // Reload the calendar if the user theme changes
  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        // Re-render the calendar
        calendarApi.render();
      }
    }
  }, [user_theme]);

  return (
    <FullCalendar
      allDayText="All Day"
      longPressDelay={300}
      eventColor="#FFE3C9"
      eventTextColor="#3c4043"
      selectable={true}
      selectMirror={true}
      ref={calendarRef}
      headerToolbar={false}
      firstDay={calendar_first_day_of_week}
      weekends={calendar_show_weekends}
      // header={false}
      plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
      initialView={
        calendar_view === "day"
          ? "timeGridDay"
          : calendar_view === "week"
          ? "timeGridWeek"
          : calendar_view === "timeGridTwoDay"
          ? "timeGridTwoDay"
          : calendar_view === "timeGridThreeDay"
          ? "timeGridThreeDay"
          : calendar_view === "timeGridFourDay"
          ? "timeGridFourDay"
          : calendar_view === "timeGridFiveDay"
          ? "timeGridFiveDay"
          : calendar_view === "timeGridSixDay"
          ? "timeGridSixDay"
          : "dayGridMonth"
      }
      slotMinTime={slotMinTime}
      height="100%"
      dayHeaders={!todayMode}
      snapDuration={calendar_snap_duration}
      dayMaxEvents={3}
      views={{
        timeGridTwoDay: {
          type: "timeGrid",
          duration: { days: 2 },
        },
        timeGridThreeDay: {
          type: "timeGrid",
          duration: { days: 3 },
        },
        timeGridFourDay: {
          type: "timeGrid",
          duration: { days: 4 },
        },
        timeGridFiveDay: {
          type: "timeGridWeek",
          hiddenDays: [0, 6],
        },
        timeGridSixDay: {
          type: "timeGridWeek",
          hiddenDays: [0],
        },
      }}
      stickyHeaderDates={false}
      moreLinkContent={({ num }) => {
        return (
          <MoreLink
            allTasksForDay={allTasksForDay}
            dateForCurrentDay={dateForCurrentDay}
            googleEvents={googleEvents}
            num={num}
            calendar_view={calendar_view}
            user_theme={user_theme}
            setMoreLinkActive={setMoreLinkActive}
          />
        );
      }}
      nowIndicator={true}
      //  showNonCurrentDates={false}
      // When clicking the event, print out all events to the console
      moreLinkClick={(arg) => {
        // get allSegs from arg
        setAllTasksForDay(arg.allSegs);
        setAllDateForCurrentDay(arg.date);
        setMoreLinkActive(true);

        // disable showing default popover
        return <div></div>;
      }}
      dayHeaderContent={({ date, isToday }) => {
        // Get timezone from moment
        // Get locale (should be in format en-US), don't use moment.locale() because its just en

        var locale = window.navigator.userLanguage || window.navigator.language;

        const weeklabelForMonth = new Intl.DateTimeFormat(locale, {
          timeZone: "UTC",
          weekday: "short",
        }).format(date);

        return (
          <div className="calendar-header-custom">
            <div>
              {calendar_view === "month"
                ? weeklabelForMonth
                : moment(date).format("ddd")}{" "}
              {calendar_view !== "month" && (
                <span className={isToday ? "calendar-header-today" : ""}>
                  {moment(date).format("D")}{" "}
                </span>
              )}
            </div>
          </div>
        );
      }}
      unselectCancel={".create-task-modal"}
      editable={true}
      droppable={true}
      allDaySlot={true}
      dropAccept={".card-draggable"}
      events={[
        ...Object.values(appleEvents || {}).map((appleEvent) => {
          const color = apple_cal_color[appleEvent.calendar] || "#000000";

          const closestSet = closestColorSet(
            color,
            getThemeFromUser(user_theme) === "dark" ? darkColorSets : colorSets
          );

          const textColor = closestSet.text;
          const backgroundColor = closestSet.background;
          return {
            ...appleEvent,
            textColor: textColor,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            editable:
              appleEvent.extendedProps?.recurring == true ? false : true,
          };
        }),
        ...Object.values(googleEvents || {})
          .filter(
            (googleEvent) =>
              // If extendedProps.ellie_task_id exists, then we don't want to show it'
              !googleEvent.extendedProps ||
              !googleEvent.extendedProps.ellie_task_id
          )
          // Filter declined if show_declined_events is false
          .filter((googleEvent) =>
            !show_declined_events ? !googleEvent.extendedProps?.declined : true
          )
          .map((googleEvent) => {
            // Go through and update textColor and backgroundColor based on the primary color

            const primaryColor = googleEvent.backgroundColor;

            const closestSet = closestColorSet(
              primaryColor,
              getThemeFromUser(user_theme) === "dark"
                ? darkColorSets
                : colorSets
            );

            const textColor = closestSet.text;
            const backgroundColor = closestSet.background;
            return {
              ...googleEvent,
              textColor: textColor,
              backgroundColor: backgroundColor,
              borderColor: backgroundColor,
            };
          }),
        ...Object.values(outlookEvents || {})
          .filter(
            (outlookEvent) =>
              !outlookEvent.extendedProps ||
              !outlookEvent.extendedProps.ellie_task_id
          )
          .filter((outlookEvent) => {
            // Filter declined if show_declined_events is false
            return !show_declined_events
              ? !outlookEvent.extendedProps?.declined
              : true;
          })
          .map((outlookEvent) => {
            const primaryColor =
              outlookEvent.extendedProps?.data?.color || "#0072C6";

            const closestSet = closestColorSet(
              primaryColor,
              getThemeFromUser(user_theme) === "dark"
                ? darkColorSets
                : colorSets
            );

            const textColor = closestSet.text;
            const backgroundColor = closestSet.background;

            return {
              ...outlookEvent,
              textColor: textColor,
              backgroundColor: backgroundColor,
              borderColor: backgroundColor,
            };
          }),
        ...Object.values(taskEvents),
      ]}
      initialDate={moment().format("YYYY-MM-DD")}
      scrollTimeReset={false}
      scrollTime={scrollTime}
      fixedWeekCount={false}
      eventResizableFromStart={true}
      eventContent={(eventObject) => {
        const event = eventObject.event;

        if (event.extendedProps?.type === "apple") {
          const appleEvent = appleEvents[event.id];

          if (appleEvent) {
            const color = apple_cal_color[appleEvent.calendar] || "#000000";

            const closestSet = closestColorSet(
              color,
              getThemeFromUser(user_theme) === "dark"
                ? darkColorSets
                : colorSets
            );

            return (
              <AppleCalendarEvent
                appleEventId={appleEvent.id}
                eventObject={eventObject}
                textColor={closestSet.text}
                calendarView={calendar_view}
              />
            );
          }
        }

        if (googleEvents) {
          const googleEvent = googleEvents[event.id];
          if (googleEvent) {
            const closestSet = closestColorSet(
              googleEvent.backgroundColor,
              getThemeFromUser(user_theme) === "dark"
                ? darkColorSets
                : colorSets
            );

            return (
              <GoogleCalendarEvent
                googleEvent={googleEvent}
                event={event}
                eventObject={eventObject}
                textColor={closestSet.text}
                calendarView={calendar_view}
              />
            );
          }
        }

        if (outlookEvents) {
          const outlookEvent = outlookEvents[event.id];

          if (outlookEvent) {
            const closestSet = closestColorSet(
              outlookEvent.extendedProps?.data?.color || "#0072C6",
              getThemeFromUser(user_theme) === "dark"
                ? darkColorSets
                : colorSets
            );

            return (
              <OutlookCalendarEvent
                outlookEvent={outlookEvent}
                event={event}
                eventObject={eventObject}
                textColor={closestSet.text}
                calendarView={calendar_view}
              />
            );
          }
        }

        if (event.extendedProps?.type === "task") {
          return (
            <TaskCalendarEvent
              taskId={event.id}
              event={event}
              eventObject={eventObject}
              textColor={event.backgroundColor}
              calendarView={calendar_view}
              moreLinkActive={moreLinkActive}
            />
          );
        }
      }}
      eventClassNames={(arg) => {
        const event = arg.event;
        const task = tasks[event.id];

        // Check if previewFromTimebox is true
        if (event.extendedProps?.previewFromTimebox) {
          return ["preview-task"];
        }

        if (task && task.complete) {
          if (calendar_view !== "month") {
            return ["completed-task", "less-padding"];
          } else {
            return ["completed-task"];
          }
        }

        if (task && calendar_view !== "month") {
          return ["less-padding"];
        }
      }}
      eventDidMount={(arg) => {
        const eventId = arg.event.id;

        const googleEvent = googleEvents[eventId];

        const appleEvent = appleEvents[eventId];

        const outlookEvent = outlookEvents[eventId];

        arg.el.style.borderRadius = "5px";
        if (appleEvent) {
          const color = apple_cal_color[appleEvent.calendar] || "#000000";

          if (calendar_view !== "month") {
            arg.el.style.borderLeft = `3px solid ${color}`;
          }
          if (calendar_view === "month") {
            arg.el.style.border = `none`;
          }
        }

        if (outlookEvent) {
          if (calendar_view !== "month") {
            arg.el.style.borderLeft = `3px solid ${
              outlookEvent.extendedProps?.data?.color || "#0072C6"
            }`;
          }
          if (calendar_view === "month") {
            arg.el.style.border = `none`;
          }
        }

        if (googleEvent) {
          // Depending on theuser theme, we will use a different color set

          //   arg.el.style.border = "none";
          //  arg.el.style.backgroundColor = closestSet.background;
          //  arg.el.style.color = closestSet.text;
          if (calendar_view !== "month") {
            arg.el.style.borderLeft = `3px solid ${googleEvent.backgroundColor}`;
          }
          if (calendar_view === "month") {
            arg.el.style.border = `none`;
          }
        } else {
          arg.el.addEventListener("contextmenu", (jsEvent) => {
            jsEvent.preventDefault();
            dispatch(setContextMenuActiveForEvent(eventId));
          });
        }
      }}
      eventClick={(arg) => {
        const event = arg.event;
        const task = tasks[event.id];

        const googleEvent = googleEvents[event.id];

        if (googleEvent) {
          // setEventModalActive(event.id);
        }

        if (task) {
          dispatch(setColumnSelected(null));
          dispatch(setCardModalActive(task.id));
        }
      }}
      select={(arg) => {
        const { start, end } = arg;
        const oneDayInMillis = 24 * 60 * 60 * 1000;

        if (calendar_view === "month") {
          if (arg.end - arg.start > oneDayInMillis) {
            // If the selection spans more than one day, prevent it
            arg.view.calendar.unselect();
            return;
          }
        }

        if (!subscriptionActive) {
          dispatch(setUpgradeVisible(true));
        } else {
          const currentDate = moment(); // Get the current date and time
          const selectedDate = moment(start); // Get the selected date
          selectedDate.hours(currentDate.hours()); // Set the hour to the current hour
          selectedDate.minutes(currentDate.minutes()); // Set the minute to the current minute
          selectedDate.seconds(currentDate.seconds()); // Set the second to the current second

          dispatch(
            setCreateTaskModalActive({
              active: true,
              date:
                calendar_view === "month"
                  ? selectedDate.format("YYYY-MM-DD")
                  : moment(start).format("YYYY-MM-DD"),
              start_date:
                calendar_view === "month"
                  ? selectedDate.toDate()
                  : moment(start).toDate(),
              duration:
                calendar_view === "month"
                  ? 30 * 60
                  : moment(end).diff(moment(start), "seconds"),
            })
          );
        }
      }}
      slotDuration={"00:30:00"}
      slotLabelInterval={{
        hours: 1,
      }}
      slotLabelFormat={{
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
        hour12: timeFormat,
      }}
      eventTimeFormat={{
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: false,
        hour12: timeFormat,
      }}
      eventMinHeight={20}
      eventChange={(changeInfo) => {
        const event = changeInfo.event;

        if (event.extendedProps?.type == "task") {
          // Update events state

          dispatch(
            updateTaskEvent({
              id: event.id,
              newEventData: {
                start: event.start,
                end: event.end,
              },
            })
          );

          // Log event change
          setEventChanges((eventChanges) => {
            return {
              ...eventChanges,
              [event.id]: event,
            };
          });
        }

        if (event.extendedProps?.type == "google") {
          const googleEvent = googleEventData[event.id];

          const newEventData = {
            start: {
              ...googleEvent.start,
              dateTime: event.start.toISOString(),
            },
            end: {
              ...googleEvent.end,
              dateTime: event.end.toISOString(),
            },
          };

          // First, let's check if there are other attendees on the event
          if (googleEvent.attendees && googleEvent.attendees.length > 1) {
            // If there are, we need to prompt the user if they want to send updates to all attendees
            dispatch(
              stageGoogleEvent({
                originalCalendarEvent: event,
                newGoogleEventData: newEventData,
              })
            );
          } else {
            dispatch(
              updateGoogleCalendarEvent({
                eventId: googleEvent.id,
                newData: newEventData,
                sendUpdates: "none",
              })
            );
          }
        }

        if (event.extendedProps?.type === "apple") {
          const appleEvent = appleEvents[event.id];

          const newEventData = {
            start: event.start.toISOString(),
            end: event.end.toISOString(),
          };

          dispatch(
            updateAppleCalendarEvent({
              appleEvent: appleEvent,
              newData: newEventData,
            })
          );
        }

        if (event.extendedProps?.type === "outlook") {
          const outlookEvent = outlookEvents[event.id];

          const newEventData = {
            start: event.start.toISOString(),
            end: event.end.toISOString(),
          };

          dispatch(
            updateOutlookCalendarEvent({
              eventId: outlookEvent.id,
              newData: newEventData,
            })
          );
        }
      }}
      eventReceive={(info) => {
        const event = info.event;

        if (bulkSelectedTasks.length > 1) {
          return
        }

        if (!subscriptionActive) {
          dispatch(setUpgradeVisible(true));
          info.revert();
        } else {
          analytics("Task timeboxed", {
            method: "dragged",
          });

          dispatch(updateTaskFromEvent(event));

          info.revert();
        }
      }}
    />
  );
}
